import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default function useSupplier() {
  const suppliers = ref([])

  const columns = ref([
    {
      label: 'Supplier',
      field: 'company_name',
      filterOptions: {
        enabled: true,
        placeholder: 'Supplier',
      },
    },

    {
      label: 'Email Address',
      field: 'email',
      filterOptions: {
        enabled: true,
        placeholder: 'email',
      },
    },
    {
      label: 'Action',
      field: 'action',
    },
  ])

  const getSuppliers = async () => {
    const { data } = await axiosIns.get('/suppliers/', { params: { is_deleted: false } })
    suppliers.value = data
  }

  return {
    suppliers,
    getSuppliers,
    columns,
  }
}
